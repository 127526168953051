import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Heading, HStack, IconButton, Link, Stack, useDisclosure } from "@chakra-ui/react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose, AiOutlineWhatsApp } from "react-icons/ai";
import { useRef } from "react";

interface NavLinkProps {
  index?: string;
  name: string;
  path: string;
  onClose: () => void;
}

const whatsappMessage = "https://wa.me/+51993652788/?text=Estimada+psic%c3%b3loga%2c+tengo+la+siguiente+consulta";

const WhatsAppLink = (props: NavLinkProps) => {
  return (
    <Link
      as={"a"}
      px={2}
      py={1}
      marginLeft="auto"
      display="flex"
      flexDirection={"row"}
      alignItems="center"
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: "gray.900",
        textColor: "white"
      }}
      _activeLink={{
        bg: "gray.800",
        textColor: "white"
      }}
      onClick={() => props.onClose()}
      target="_blank"
      href={props.path}
    >
      <Box as="span">{props.name}</Box>
      <Box as="span" marginLeft={1}><AiOutlineWhatsApp size={20} /></Box>
    </Link>
  )
}

const NavLink = (props: NavLinkProps) => {
  return (
    <Link
      as={RouterNavLink}
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: "gray.900",
        textColor: "white"
      }}
      _activeLink={{
        bg: "gray.800",
        textColor: "white"
      }}
      onClick={() => props.onClose()}
      to={props.path}
    >
      {props.name}
    </Link>
  );
};

const TopNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef: any = useRef();

  return (
    <Box bg="gray.700" color="white" px={4} boxShadow={"xl"} position="sticky" top="0" zIndex="10">
      <Flex 
        alignItems="center" 
        w={["100%", "100%", "80%"]} 
        maxW={800}
        mx="auto" 
        justifyContent="space-between" 
        paddingY={4}>
        <HStack>
          <IconButton
            ref={btnRef}
            size={"md"}
            icon={isOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
            aria-label={"Open Menu"}
            marginRight={[2, 4, 0]}
            bg="gray.900"
            _active={{
              bg: "gray.800",
            }}
            _hover={{
              bg: "gray.800",
            }}
            display={["inherit", "inherit", "none"]}
            onClick={isOpen ? onClose : onOpen}
            />
          <Heading as="h1" fontSize={["1.1rem","2rem","3rem"]}>Psicóloga Beatriz Sifuentes</Heading>
        </HStack>
      </Flex>
      <Flex
        paddingY={4}
        direction={"column"}
        display={{ base: "none", md: "flex" }}
        alignItems={"start"}
        w={["90%", "85%", "80%"]}
        maxW={800}
        mx="auto"
      >
        <Box 
          flexDirection={"row"}
          gap={4}
          as={"nav"}
          width="100%"
          display="flex">
          <NavLink name={"Inicio"} path={"/"} onClose={onClose} />
          {
            // <NavLink name={"Pensamientos"} path={"/reflextions"} onClose={onClose} />
          }
          <NavLink name={"Servicios"} path={"/services"} onClose={onClose} />
          <WhatsAppLink 
            name={"Contáctame"} 
            path={whatsappMessage} 
          onClose={onClose} />
        </Box>
      </Flex>
      <Drawer 
      isOpen={isOpen}
      placement='left'
      onClose={onClose}
      finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerHeader>Menú</DrawerHeader>
          <DrawerBody>
            <Box
              marginY={1}
            >
              <Stack as={"nav"} spacing={4}>
                <NavLink name={"Inicio"} path={"/"} onClose={onClose} />
                <NavLink name={"Servicios"} path={"/services"} onClose={onClose} />
                <WhatsAppLink name={"Contáctame"} path={whatsappMessage} onClose={onClose} />
              </Stack>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
  }
  
  export default TopNav;
  