import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import './App.css';
import { Box, ChakraProvider, Spinner } from '@chakra-ui/react';
import { lazy, Suspense } from "react";
import TopNav from "./components/TopNav";
import Template from "./components/Template";
import Footer from "./components/Footer";

const Home = lazy(() => import('./pages/home'));
// const Reflextions = lazy(() => import('./pages/reflextions'));
const Services = lazy(() => import('./pages/services'));
// const Contact = lazy(() => import('./pages/contact'));
const NotFound = lazy(() => import('./pages/404'));

const App = () => {
  return (
    <ChakraProvider>
      <Router>
        <Box position="relative">
          <TopNav />
          <Suspense fallback={<Template><Spinner size="xl" /></Template>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/services" element={<Template><Services /></Template>} />
              {
                // <Route path="/reflextions" element={<Template><Reflextions /></Template>} />
                // <Route path="/contact" element={<Template><Contact /></Template>} />
              }
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
          <Footer />
        </Box>
      </Router>
    </ChakraProvider>
  )
}

export default App;
