import {
    Box,
    chakra,
    Container,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
} from '@chakra-ui/react';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { ReactNode } from 'react';
  
const SocialButton = ({
    children,
    label,
    href,
}: {
    children: ReactNode;
    label: string;
    href: string;
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            target="_blank"
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
        }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

const Footer = () => {
    const date = new Date();
    return (
        <Box 
            borderTopColor="gray.900!important" 
            borderTopWidth="1px"
            borderTopStyle="solid"
            color={useColorModeValue('gray.700', 'gray.200')}>
            <Container
                as={Stack}
                py={4}
                textAlign="center"
                fontSize="xl"
                w={["90%", "85%", "80%"]}
                maxW={800}
                mx="auto"
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                justify={{ base: 'center', md: 'space-between' }}
                align={{ base: 'center', md: 'center' }}>
                <Text>{date.getFullYear()} - Psicóloga Beatriz Sifuentes</Text>
                <Stack direction={'row'} spacing={6}>
                <SocialButton label={'LinkedIn'} href={'https://www.linkedin.com/in/beatriz-sifuentes-capu%C3%B1ay-9706b339/'}>
                    <FaLinkedin />
                </SocialButton>
                <SocialButton label={'Facebook'} href={'https://www.facebook.com/Psicologabeatrizsifuentess'}>
                    <FaFacebook />
                </SocialButton>
                </Stack>
            </Container>
        </Box>
    );
}

export default Footer;